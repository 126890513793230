import { Box, useMediaQuery } from '@mui/material'
import DetailsSidebar from 'components/DetailsSidebar'
import React, { useState } from 'react'
import { Outlet } from 'react-router-dom'

const DetailsDashboard = () => {
	const isNonMobile = useMediaQuery('(min-width: 600px)')
	const [isSidebarOpen, setIsSidebarOpen] = useState(true)
	return (
		<Box
			sx={{ width: '700px' }}
			display={isNonMobile ? 'flex' : 'block'}
			height="100%">
			<DetailsSidebar
				isNonMobile={isNonMobile}
				drawerWidth="260px"
				isSidebarOpen={isSidebarOpen}
				setIsSidebarOpen={setIsSidebarOpen}
			/>
			<Box flexGrow={1}>
				<Outlet />
			</Box>
		</Box>
	)
}
console.log(
	'🚀 ~ file: index.jsx:26 ~ DetailsDashboard ~ <Outlet />',
	<Outlet />,
)

export default DetailsDashboard
