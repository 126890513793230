import * as React from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@material-ui/lab/TabContext";
import TabList from "@material-ui/lab/TabList";
import TabPanel from "@material-ui/lab/TabPanel";
import UserGroups from "./membersTabs/UserGroups";
import Transactions from "./membersTabs/Transactions";

export default function MemberTabs(memberData) {
  const [value, setValue] = React.useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%", typography: "body1" }}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab label="User Groups" value="1" />
            <Tab label="Transactions" value="2" />
          </TabList>
        </Box>
        <TabPanel value="1">
          <UserGroups memberData={memberData} />
        </TabPanel>
        <TabPanel value="2">
          <Transactions memberData={memberData} />
        </TabPanel>
      </TabContext>
    </Box>
  );
}
