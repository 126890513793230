import React, { useEffect, useMemo, useState } from "react";
import { Box, useTheme } from "@mui/material";
import Header from "components/Header";
import { DataGrid } from "@mui/x-data-grid";
import { format } from "date-fns";
import MembersTabModal from "components/MembersTabModal";
import { useStateContext } from "contexts/ContextProvider";
import DataGridCustomToolbar from "components/DataGridCustomToolbar";
import useAuth from "hooks/useAuth";
import axios from "axios";

const Customers = () => {
  const { memberModalOpen, setMemberModalOpen, setMemberData } =
    useStateContext();
  const theme = useTheme();

  const { branch } = useAuth();
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [data, setData] = useState([]);
  const [rowCount, setRowCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [searchData, setSearchData] = useState([]);
  const [search, setSearch] = useState(false);

  const [searchInput, setSearchInput] = useState("");

  const memoizedData = useMemo(() => data, [data]);
  const memoizedCount = useMemo(() => rowCount, [rowCount]);
  //write a function to get the count of the campaigns
  const getUserCount = async () => {
    if (rowCount === 0) {
      await axios
        .get(`${process.env.REACT_APP_BASE_URL}/users/count/${branch}`)
        .then(function (response) {
          setRowCount(response.data.count);
        })
        .catch(function (error) {
          console.log(error);
        });
    } else {
      return;
    }
  };

  useEffect(() => {
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/users/bybranch/${branch}`, {
        page,
        pageSize,
      })
      .then(function (response) {
        setData(response.data);
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
      });
    getUserCount();
  }, [page]);

  useEffect(() => {
    if (!search) return;

    setLoading(true);

    axios
      .post(`${process.env.REACT_APP_BASE_URL}/users/search`, {
        searchInput,
      })
      .then(({ data }) => {
        setSearchData(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [search]);

  const columns = [
    { field: "PhoneNumber", headerName: "Phone Number", flex: 0.5 },
    { field: "ClientName", headerName: "Name", flex: 0.5, renderCell: (params) => {if(!params.value){return 'Not In Musoni'}}  },
    { field: "userType", headerName: "User Type", flex: 0.5 },
    { field: "status", headerName: "Status", flex: 0.5 },
    {
      field: "creationDate",
      headerName: "Creation Date",
      flex: 1,
      renderCell: (params) => format(new Date(params.value), "dd/MM/yyyy"),
    },
  ];

  return (
    <Box m="1.5rem 2.5rem">
      <Header title="Members" subtitle="List Of Members" />
      <Box
        mt="40px"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": { border: "none" },
          "& .MuiDataGrid-cell": { borderBottom: "none" },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: theme.palette.primary.light,
          },
          "& .MuiDataGrid-footercontainer": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderTop: "none",
          },
          "& .MuiDataGrid-toolbatContainer .MuiButton-text": {
            color: `${theme.palette.secondary[200]} !important`,
          },
        }}
      >
        <DataGrid
          loading={loading}
          getRowId={(row) => row.id}
          rows={search ? searchData : memoizedData || []}
          columns={columns}
          initialState={{
            sorting: {
              sortModel: [{ field: "creationDate", sort: "desc" }],
            },
          }}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          rowsPerPageOptions={[10, 20, 50]}
          pagination
          components={{ Toolbar: DataGridCustomToolbar }}
          componentsProps={{
            toolbar: { searchInput, setSearchInput, setSearch, search },
          }}
          rowCount={memoizedCount}
          paginationMode="server"
          page={page}
          onPageChange={(newPage) => {
            setPage(newPage);
            setLoading(true);
          }}
          onRowClick={(row) => {
            console.log(row);
            setMemberData({ id: row.row.id });
            setMemberModalOpen(true);
            console.log(row.row.id);
          }}
        />
      </Box>
      <MembersTabModal open={memberModalOpen} />
    </Box>
  );
};

export default Customers;
