import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'
import globalReducer from 'state'
import authReducer from 'slices/auth/authSlice'
import { apiSlice } from 'app/api/apiSlice'

export const store = configureStore({
	reducer: {
		global: globalReducer,
		auth: authReducer,
		[apiSlice.reducerPath]: apiSlice.reducer,
	},
	middleware: (getDefault) =>
		getDefault({
			immutableCheck: false,
			serializableCheck: false,
		}).concat(apiSlice.middleware),
})

setupListeners(store.dispatch)
