import { Box } from '@mui/material'
import Header from 'components/Header'
import React from 'react'

const GeneralInfo = () => {
	return (
		<Box m="1.5rem 2.5rem">
			<Header title="General Info" subtitle="General Info" />
			<Box
				mt="20px"
				display="grid"
				gridTemplateColumns="repeat(2, 1fr)"
				gridAutoRows="160px"
				gap="20px">
				<Box>Left</Box>

				<Box>Right</Box>
			</Box>
		</Box>
	)
}

export default GeneralInfo
