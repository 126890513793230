import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useStateContext } from "contexts/ContextProvider";
import CampaignTabs from "./CampaignTabs";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60%",
  height: "60%",
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
  //   p: 4,
  borderRadius: 2,
};

export default function CampaignsTabModal() {
  const { campaignModalOpen, setCampaignModalOpen } = useStateContext();
  const handleClose = () => setCampaignModalOpen(false);

  return (
    <div>
      <Modal
        open={campaignModalOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <CampaignTabs />
        </Box>
      </Modal>
    </div>
  );
}
