import { store } from "app/store";
import { adminsApiSlice } from "slices/admin/adminApiSlice";
import { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { clientApiSlice } from "slices/client/clientApiSlice";

const Prefetch = () => {
  useEffect(() => {
    const admins = store.dispatch(
      adminsApiSlice.endpoints.getAdmins.initiate()
    );
    return () => {
      admins.unsubscribe();
    };
  }, []);
  return <Outlet />;
};

export default Prefetch;
