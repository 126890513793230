import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

const borderColor = "#3778C2";
const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    borderBottomColor: "#3778C2",
    backgroundColor: "#3778C2",
    color: "#fff",
    borderBottomWidth: 1,
    alignItems: "center",
    height: 24,
    textAlign: "center",
    fontStyle: "bold",
    flexGrow: 1,
  },
  date: {
    width: "20%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
  },
  savings: {
    width: "20%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
  },
  withdraws: {
    width: "25%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
  },
  paybacks: {
    width: "20%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
  },
  balance: {
    width: "20%",
  },
});

const ReportPDFTableHeader = () => (
  <View style={styles.container}>
    <Text style={styles.date}>Date</Text>
    <Text style={styles.savings}>Savings</Text>
    <Text style={styles.withdraws}>Withdraws</Text>
    <Text style={styles.paybacks}>Paybacks</Text>
    <Text style={styles.balance}>Group Balance</Text>
  </View>
);

export default ReportPDFTableHeader;
