import * as React from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@material-ui/lab/TabContext";
import TabList from "@material-ui/lab/TabList";
import TabPanel from "@material-ui/lab/TabPanel";
import Approvers from "./campaignTabs/Approvers";
import Transactions from "./campaignTabs/Transactions";
import GroupMembers from "./campaignTabs/GroupMembers";

export default function CampaignTabs(memberData) {
  const [value, setValue] = React.useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%", typography: "body1" }}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab label="Group Members" value="1" />
            <Tab label="Group Approvers" value="2" />
            <Tab label="Group Transactions" value="3" />
          </TabList>
        </Box>
        <TabPanel value="1">
          <GroupMembers />
        </TabPanel>
        <TabPanel value="2">
          <Approvers />
        </TabPanel>
        <TabPanel value="3">
          <Transactions />
        </TabPanel>
      </TabContext>
    </Box>
  );
}
