import {
	ChevronRightOutlined,
	Groups2Outlined,
	Person2Outlined,
	ReceiptLongOutlined,
} from '@mui/icons-material'
import {
	Box,
	Drawer,
	List,
	ListItem,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	Typography,
	useTheme,
} from '@mui/material'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { selectMode } from 'state'

const drawerWidth = '240px'

const navItems = [
	{
		text: 'Info',
		icon: <Person2Outlined />,
	},
	{
		text: 'Group Members',
		icon: <Groups2Outlined />,
	},

	,
	{
		text: 'Group Approvers',
		icon: <Groups2Outlined />,
	},
	{
		text: 'Transactions',
		icon: <ReceiptLongOutlined />,
	},
]

const DetailsSidebar = ({ isNonMobile }) => {
	const { pathname } = useLocation()
	const [active, setActive] = useState('')
	const navigate = useNavigate()
	const theme = useTheme()
	const mode = useSelector(selectMode)

	const location = useLocation()

	const [isSidebarOpen, setIsSidebarOpen] = useState(true)

	return (
		<Box component="nav">
			<Box
				open={isSidebarOpen}
				onClose={() => setIsSidebarOpen(false)}
				variant="persistent"
				anchor="left"
				sx={{
					color: theme.palette.secondary[200],
					backgroundColor: theme.palette.background.alt,
					boxSixing: 'border-box',
					borderWidth: isNonMobile ? 0 : '2px',
					width: drawerWidth,
				}}>
				<Box width="100%">
					<List>
						{navItems.map(({ text, icon }) => {
							if (!icon) {
								return (
									<Typography key={text} sx={{ m: '2.25rem 0 1rem 3rem' }}>
										{text}
									</Typography>
								)
							}
							const lcText = text.toLowerCase()
							return (
								<ListItem key={text} disablePadding>
									<ListItemButton
										onClick={() => {
											// navigate(`/${lcText}`)
											setActive(active)
										}}
										sx={{
											borderRadius: '0.5rem',
											display: 'flex',
											flexDirection: 'column',
											alignItems: 'center',

											backgroundColor:
												active === lcText
													? theme.palette.secondary[300]
													: 'transparent',

											color:
												active === lcText
													? 'white'
													: theme.palette.secondary[100],
											'&:hover': {
												color: theme.palette.secondary[100],
											},
										}}>
										<Link to={`/${lcText}`} state={{ background: location }}>
											<ListItemIcon
												sx={{
													ml: '2rem',
													color:
														active === lcText
															? 'white'
															: theme.palette.secondary[200],
												}}>
												{icon}
											</ListItemIcon>
											<ListItemText primary={text} />
											{active === lcText && (
												<ChevronRightOutlined sx={{ ml: 'auto' }} />
											)}
										</Link>
									</ListItemButton>
								</ListItem>
							)
						})}
					</List>
				</Box>
			</Box>
		</Box>
	)
}

export default DetailsSidebar
