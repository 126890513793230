import {
  AddCircleOutline,
  CloseOutlined,
  DeleteForeverOutlined,
  Edit,
  EditOffOutlined,
} from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Toolbar,
  Typography,
  useTheme,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import ActionButton from "components/ActionButton";
import AddAdmin from "components/AddAdmin";
import EditAdmin from "components/EditAdmin";
import FlexBetween from "components/FlexBetween";
import Header from "components/Header";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  useDeleteAdminMutation,
  useGetAdminsQuery,
} from "slices/admin/adminApiSlice";
import { selectOpen, selectOpenEdit, setOpen, setOpenEdit } from "state";

const Admin = () => {
  const { data, isLoading } = useGetAdminsQuery("adminsList", {
    refetchOnMountOrArgChange: true,
    pollingInterval: 1500000,
    // refetchOnFocus: true,
  });

  const dataArray = data?.entities ? Object.values(data.entities) : [];

  console.log(dataArray);

  const [
    deleteAdmin,
    {
      isLoading: isDelLoading,
      isSuccess: isDelSuccess,
      isError: isDelError,
      error: delError,
    },
  ] = useDeleteAdminMutation();

  const theme = useTheme();

  const dispatch = useDispatch();

  const open = useSelector(selectOpen);

  const openEdit = useSelector(selectOpenEdit);

  const [openDelete, setOpenDelete] = useState(false);

  const [id, setId] = useState("");

  const columns = [
    { field: "_id", headerName: "ID", flex: 1 },
    { field: "username", headerName: "Username", flex: 1 },
    { field: "email", headerName: "Email", flex: 1 },
    { field: "roles", headerName: "Role", flex: 0.5 },
    { field: "branch", headerName: "Branch", flex: 0.5 },
    {
      id: "action",
      headerName: "Action",
      flex: 1,
      renderCell: (params) => {
        return (
          <FlexBetween gap={2}>
            <div
              onClick={() => {
                dispatch(setOpenEdit(true));
                setId(params.row._id);
              }}
              className=" hover:scale-105 duration-300 cursor-pointer rounded p-2 bg-[#d7de42] text-white ml-2"
            >
              <Edit />
            </div>
            <div
              onClick={() => {
                setOpenDelete(true);
                setId(params.row._id);
              }}
              className=" hover:scale-105 duration-300 cursor-pointer rounded p-2 bg-[#e65353] text-white"
            >
              <DeleteForeverOutlined />
            </div>
          </FlexBetween>
        );
      },
    },
  ];

  const handleDelete = async () => {
    setTimeout(() => {
      setOpenDelete(false);
    }, 15000);
    await deleteAdmin({ id: id });
  };

  return (
    <Box m="1.5rem 2.5rem">
      <Header title="Admin" subtitle="Manage your Admins" />
      <Box
        mt="40px"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": { border: "none" },
          "& .MuiDataGrid-cell": { borderBottom: "none" },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: theme.palette.primary.light,
          },
          "& .MuiDataGrid-footercontainer": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderTop: "none",
          },
          "& .MuiDataGrid-toolbatContainer .MuiButton-text": {
            color: `${theme.palette.secondary[200]} !important`,
          },
        }}
      >
        <Toolbar>
          <Button
            variant="outlined"
            color="secondary"
            sx={{
              position: "absolute",
              right: "10px",
              mt: 3,
              mb: 2,
              p: "0.5rem 1.5rem",
            }}
            onClick={() => dispatch(setOpen(true))}
            startIcon={<AddCircleOutline />}
          >
            Add Admin
          </Button>
        </Toolbar>
        <DataGrid
          loading={isLoading || !data}
          getRowId={(row) => row._id}
          rows={dataArray || []}
          columns={columns}
        />
      </Box>
      <Dialog open={open} onClose={() => dispatch(setOpen(false))}>
        <DialogTitle
          margin="md"
          sx={{
            color: theme.palette.secondary[200],
            backgroundColor: theme.palette.background.alt,
            display: "flex",
          }}
        >
          <Typography
            variant="h2"
            color={theme.palette.secondary[100]}
            sx={{ mb: "5px", flexGrow: 1 }}
          >
            Create User
          </Typography>
          <ActionButton
            color="secondary"
            onClick={() => dispatch(setOpen(false))}
          >
            <CloseOutlined />
          </ActionButton>
        </DialogTitle>
        <DialogContent
          dividers
          sx={{
            color: theme.palette.secondary[200],
            backgroundColor: theme.palette.background.alt,
          }}
        >
          <AddAdmin />
        </DialogContent>
      </Dialog>

      {/* Edit */}
      <Dialog open={openEdit} onClose={() => dispatch(setOpenEdit(false))}>
        <DialogTitle
          margin="md"
          sx={{
            color: theme.palette.secondary[200],
            backgroundColor: theme.palette.background.alt,
            display: "flex",
          }}
        >
          <Typography
            variant="h2"
            color={theme.palette.secondary[100]}
            sx={{ mb: "5px", flexGrow: 1 }}
          >
            Edit User
          </Typography>
          <ActionButton
            color="secondary"
            onClick={() => dispatch(setOpenEdit(false))}
          >
            <CloseOutlined />
          </ActionButton>
        </DialogTitle>
        <DialogContent
          dividers
          sx={{
            color: theme.palette.secondary[200],
            backgroundColor: theme.palette.background.alt,
          }}
        >
          <EditAdmin id={id} />
        </DialogContent>
      </Dialog>

      {/* Delete Confirmation */}
      <Dialog
        open={openDelete}
        onClose={() => setOpenDelete(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Confirm the action</DialogTitle>
        <Box position="absolute" top={0} right={0}>
          <ActionButton color="secondary" onClick={() => setOpenDelete(false)}>
            <CloseOutlined />
          </ActionButton>
        </Box>
        <DialogContent>
          <Typography color="red">
            You are about to DELETE This User!
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenDelete(false);
            }}
            color="primary"
            variant="contained"
          >
            Cancel
          </Button>
          <Button onClick={handleDelete} color="secondary" variant="contained">
            {isDelLoading ? <CircularProgress size={20} /> : "Confirm"}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Admin;
