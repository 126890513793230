import React, { useMemo } from 'react'
import { Box, useTheme } from '@mui/material'
import { ResponsiveLine } from '@nivo/line'
import 'react-datepicker/dist/react-datepicker.css'
import useAuth from 'hooks/useAuth'
import { useGetChartTransactionsQuery } from 'slices/client/clientApiSlice'

const WithdrawalsChart = (isDashboard = false) => {
	const theme = useTheme()

	const { branch } = useAuth()
	const { data: withdrawals, isLoading } = useGetChartTransactionsQuery(branch)
	console.log(
		'🚀 ~ file: WithdrawalsChart.jsx:13 ~ WithdrawalsChart ~ withdrawals',
		withdrawals,
	)

	const [totalWithdrawalsLine] = useMemo(() => {
		if (!withdrawals) return []

		let monthWithdrawals = {}
		const currentDate = new Date()
		const currentYear = currentDate.getFullYear()
		const currentMonthIndex = currentDate.getMonth()

		withdrawals.forEach((withdrawal) => {
			const withdrawalDate = new Date(withdrawal.creationDate)
			const withdrawalYear = withdrawalDate.getFullYear()
			const withdrawalMonthIndex = withdrawalDate.getMonth()

			if (withdrawal.type === 'WITHDRAWAL') {
				if (
					withdrawalYear >=
					currentYear - 1
					// withdrawalMonthIndex >= currentMonthIndex - 11 &&
					// withdrawalMonthIndex <= currentMonthIndex
				) {
					const month = withdrawalDate.toLocaleString('default', {
						month: 'long',
					})
					const year = withdrawalYear.toString()
					const key = `${month} ${year}`

					if (monthWithdrawals[key]) {
						monthWithdrawals[key] += Math.abs(withdrawal.amount)
					} else {
						monthWithdrawals[key] = Math.abs(withdrawal.amount)
					}
				}
			}
		})

		let sortedMonthWithdrawals = {}

		Object.keys(monthWithdrawals)
			.sort((a, b) => {
				return new Date(`01 ${a}`) - new Date(`01 ${b}`)
			})
			.forEach((key) => {
				sortedMonthWithdrawals[key] = monthWithdrawals[key]
			})

		const totalWithdrawalsLine = {
			id: 'totalWithdrawals',
			color: '#FF5722',
			data: [],
		}

		Object.keys(sortedMonthWithdrawals).forEach((monthYear) => {
			totalWithdrawalsLine.data.push({
				x: monthYear,
				y: sortedMonthWithdrawals[monthYear],
			})
		})

		return [[totalWithdrawalsLine]]
	}, [withdrawals])

	if (isLoading || !withdrawals) return <Box>Loading...</Box>

	return (
		<ResponsiveLine
			data={totalWithdrawalsLine}
			theme={{
				axis: {
					domain: {
						line: {
							stroke: theme.palette.secondary[200],
						},
					},
					legend: {
						text: {
							fill: theme.palette.secondary[200],
						},
					},
					ticks: {
						line: {
							stroke: theme.palette.secondary[200],
							strokeWidth: 1,
						},
						text: {
							fill: theme.palette.secondary[200],
						},
					},
				},
				legends: {
					text: {
						fill: theme.palette.secondary[300],
					},
				},
				tooltip: {
					container: {
						color: theme.palette.primary.main,
					},
				},
			}}
			colors={{ datum: 'color' }}
			margin={{ top: 20, right: 50, bottom: 50, left: 70 }}
			xScale={{ type: 'point' }}
			yScale={{
				type: 'linear',
				min: 'auto',
				max: 'auto',
				stacked: false,
				reverse: false,
			}}
			yFormat=" >-.2f"
			curve="catmullRom"
			enableArea={isDashboard}
			axisTop={null}
			axisRight={null}
			axisBottom={{
				format: (v) => {
					if (isDashboard) return v.slice(0, 3)
					return v
				},
				orient: 'bottom',
				tickSize: 5,
				tickPadding: 5,
				tickRotation: 0,
				legend: isDashboard ? '' : 'Month',
				legendOffset: 36,
				legendPosition: 'middle',
			}}
			axisLeft={{
				orient: 'left',
				tickValues: 5,
				tickSize: 5,
				tickPadding: 5,
				tickRotation: 0,
				legend: isDashboard
					? ''
					: `Total ${'totalWithdrawals' === 'totalWithdrawals'} for Year`,
				legendOffset: -60,
				legendPosition: 'middle',
			}}
			enableGridX={false}
			enableGridY={false}
			pointSize={10}
			pointColor={{ theme: 'background' }}
			pointBorderWidth={2}
			pointBorderColor={{ from: 'serieColor' }}
			pointLabelYOffset={-12}
			useMesh={true}
			legends={
				isDashboard
					? [
							{
								anchor: 'bottom-right',
								direction: 'column',
								justify: false,
								translateX: 30,
								translateY: -200,
								itemsSpacing: 0,
								itemDirection: 'left-to-right',
								itemWidth: 80,
								itemHeight: 20,
								itemOpacity: 0.75,
								symbolSize: 12,
								symbolShape: 'circle',
								symbolBorderColor: 'rgba(0, 0, 0, .5)',
								effects: [
									{
										on: 'hover',
										style: {
											itemBackground: 'rgba(0, 0, 0, .03)',
											itemOpacity: 1,
										},
									},
								],
							},
					  ]
					: undefined
			}
		/>
	)
}

export default WithdrawalsChart
