import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  mode: "light",
  userId: "63701cc1f03239b7f700000e",
  year: new Date().getFullYear(),
  yearMonth: new Date().getFullYear(),
  open: false,
  openEdit: false,
};

export const globalSlice = createSlice({
  name: "global",
  initialState,
  reducers: {
    setMode: (state) => {
      state.mode = state.mode === "light" ? "dark" : "light";
    },
    setYear: (state, action) => {
      state.year = action.payload;
    },
    setYearMonth: (state, action) => {
      state.yearMonth = action.payload;
    },
    setOpen: (state, action) => {
      state.open = action.payload;
    },
    setOpenEdit: (state, action) => {
      state.openEdit = action.payload;
    },
  },
});

export const { setMode } = globalSlice.actions;

export const { setYear } = globalSlice.actions;

export const { setYearMonth } = globalSlice.actions;

export const { setOpen } = globalSlice.actions;

export const { setOpenEdit } = globalSlice.actions;

export default globalSlice.reducer;

export const selectMode = (state) => state.global.mode;

export const selectYear = (state) => state.global.year;

export const selectYearMonth = (state) => state.global.yearMonth;

export const selectOpen = (state) => state.global.open;

export const selectOpenEdit = (state) => state.global.openEdit;
