import { CssBaseline, ThemeProvider } from '@mui/material'
import { createTheme } from '@mui/material/styles'
import { themeSettings } from 'theme'
import { useSelector } from 'react-redux'
import { useMemo } from 'react'
import { Routes, Route, useLocation } from 'react-router-dom'
import Layout from 'scenes/layout'
import Dashboard from 'scenes/dashboard'
import Customers from 'scenes/customers'
import { selectMode } from 'state'
import Transactions from 'scenes/transactions'
// import Overview from "scenes/overview";
import Landing from 'scenes/landing'
import RequireAuth from 'slices/auth/RequireAuth'
import { ROLES } from 'config/ROLES'
import PersistLogin from 'slices/auth/PersistLogin'
import Prefetch from 'slices/auth/Prefetch'
import Campaigns from 'scenes/campaigns'
// import Daily from "scenes/daily";
import Monthly from 'scenes/monthly'
// import Breakdown from "scenes/breakdown";
import Admin from 'scenes/admin'
import GeneralInfo from 'scenes/general-info'
import DetailsDashboard from 'scenes/details-dashboard'
import Reports from 'scenes/reports'
import Daily from 'scenes/daily'
import Branches from 'scenes/branches'

function App() {
	const mode = useSelector(selectMode)
	const theme = useMemo(() => createTheme(themeSettings(mode)), [mode])
	const location = useLocation()
	const background = location.state && location.state.background

	return (
		<div className="App">
			<ThemeProvider theme={theme}>
				<CssBaseline />
				<Routes>
					<Route path="/" element={<Landing />} />
					<Route element={<PersistLogin />}>
						<Route element={<Prefetch />}>
							<Route
								element={
									<RequireAuth allowedRoles={[...Object.values(ROLES)]} />
								}>
								<Route element={<Layout />}>
									<Route path="/dashboard" element={<Dashboard />} />
									<Route path="/transactions" element={<Transactions />} />
									<Route
										element={<RequireAuth allowedRoles={[ROLES.SuperAdmin]} />}>
										{/* <Route path="/overview" element={<Overview />} /> */}
										{/* <Route path="/daily" element={<Daily />} /> */}
										{/* <Route path="/analytics" element={<Monthly />} /> */}
										{/* <Route path="/breakdown" element={<Breakdown />} /> */}
										<Route path="/admin" element={<Admin />} />
									</Route>
									<Route path="/branches" element={<Branches />} />
									<Route path="/analytics" element={<Daily />} />
									<Route path="/statements" element={<Reports />} />
									<Route path="/groups" element={<Campaigns />}>
										<Route element={<DetailsDashboard />}>
											<Route path="campaigns/info" element={<GeneralInfo />} />
										</Route>
									</Route>

									<Route path="/members" element={<Customers />} />
								</Route>
							</Route>
						</Route>
					</Route>

					{/* </Route> */}
				</Routes>
			</ThemeProvider>
		</div>
	)
}

export default App
