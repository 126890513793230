import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

const borderColor = "#3778C2";
const styles = StyleSheet.create({
  row: {
    flexDirection: "row",
    borderBottomColor: "#3778C2",
    borderBottomWidth: 1,
    alignItems: "center",
    height: 24,
    fontStyle: "bold",
  },
  date: {
    width: "20%",
    textAlign: "left",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    paddingLeft: 8,
  },
  savings: {
    width: "20%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: "right",
    paddingRight: 8,
  },
  withdraws: {
    width: "20%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: "right",
    paddingRight: 8,
  },
  paybacks: {
    width: "20%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: "right",
    paddingRight: 8,
  },
  balance: {
    width: "20%",
    textAlign: "right",
    paddingRight: 8,
  },
});

const transactionPurposeSeparator = (value, item) => {
  if (item.type === value) {
    return item.amount;
  }
  if (item.purpose === value) {
    return item.amount;
  } else {
    return 0;
  }
};

const ReportPDFTableFooter = ({ items }) => {
  const totalBalance = items
    ?.map((item) => item.balance)
    .reduce((accumulator, currentValue) => accumulator + currentValue, 0);
  const totalSavings = items
    ?.map((item) => transactionPurposeSeparator("SAVINGS", item))
    .reduce((accumulator, currentValue) => accumulator + currentValue, 0);
  const totalPaybacks = items
    ?.map((item) => transactionPurposeSeparator("REPAYMENT", item))
    .reduce((accumulator, currentValue) => accumulator + currentValue, 0);
  const totalWithdraws = items
    ?.map((item) => transactionPurposeSeparator("WITHDRAWAL", item))
    .reduce((accumulator, currentValue) => accumulator + currentValue, 0);
  return (
    <View style={styles.row}>
      <Text style={styles.date}>TOTAL</Text>
      <Text style={styles.savings}>
        {Number.parseFloat(totalSavings).toFixed(2)}
      </Text>
      <Text style={styles.withdraws}>
        {Number.parseFloat(totalWithdraws).toFixed(2)}
      </Text>
      <Text style={styles.paybacks}>
        {Number.parseFloat(totalPaybacks).toFixed(2)}
      </Text>
      <Text style={styles.balance}>
        {Number.parseFloat(totalBalance).toFixed(2)}
      </Text>
    </View>
  );
};

export default ReportPDFTableFooter;
