import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
	LightModeOutlined,
	DarkModeOutlined,
	Menu as MenuIcon,
	Search,
	SettingsOutlined,
	ArrowDropDownOutlined,
} from '@mui/icons-material'
import FlexBetween from 'components/FlexBetween'
import { useDispatch } from 'react-redux'
import { setMode } from 'state'
import profileImage from 'assets/user.png'
import {
	AppBar,
	Button,
	Box,
	Typography,
	IconButton,
	InputBase,
	Toolbar,
	Menu,
	MenuItem,
	useTheme,
} from '@mui/material'
import { useSendLogoutMutation } from 'slices/auth/authApiSlice'
import useAuth from 'hooks/useAuth'

const Navbar = ({ user, isSidebarOpen, setIsSidebarOpen }) => {
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const theme = useTheme()
	const [anchorEl, setAnchorEl] = useState(null)

	const { username, roles, branch } = useAuth()

	const [sendLogout] = useSendLogoutMutation()

	const isOpen = Boolean(anchorEl)
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget)
	}
	const handleClose = () => {
		setAnchorEl(null)
	}
	const handleLogOut = () => {
		setAnchorEl(null)
		sendLogout()
		navigate('/')
	}

	return (
		<AppBar
			sx={{
				position: 'static',
				background: 'none',
				boxShadow: 'none',
			}}>
			<Toolbar
				sx={{
					justifyContent: 'space-between',
				}}>
				{/* Left Side */}
				<FlexBetween>
					<IconButton onClick={() => setIsSidebarOpen(!isSidebarOpen)}>
						<MenuIcon />
					</IconButton>
				</FlexBetween>

				{/* Right Side */}
				<FlexBetween>
					<IconButton onClick={() => dispatch(setMode())}>
						{theme.palette.mode === 'light' ? (
							<LightModeOutlined sx={{ fontSize: '25px' }} />
						) : (
							<DarkModeOutlined sx={{ fontSize: '25px' }} />
						)}
					</IconButton>

					<FlexBetween>
						<Button
							onClick={handleClick}
							sx={{
								display: 'flex',
								justifyContent: 'space-between',
								alignItems: 'center',
								textTransform: 'none',
								gap: '1rem',
							}}>
							<Box
								component="img"
								alt="profile"
								src={profileImage}
								height="32px"
								width="32px"
								borderRadius="50%"
								sx={{ objectFit: 'cover' }}
							/>
							<Box textAlign="left">
								<Typography
									fontWeight="bold"
									fontSize="0.85rem"
									sx={{ color: theme.palette.secondary[100] }}>
									{username}
								</Typography>

								<Typography
									fontSize="0.75rem"
									sx={{ color: theme.palette.secondary[200] }}>
									Branch: {branch}
								</Typography>
							</Box>
							<ArrowDropDownOutlined
								sx={{ color: theme.palette.secondary[300], fontSize: '25px' }}
							/>
						</Button>
						<Menu
							anchorEl={anchorEl}
							open={isOpen}
							onClose={handleClose}
							anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
							<MenuItem onClick={handleLogOut}>Log Out</MenuItem>
						</Menu>
					</FlexBetween>
				</FlexBetween>
			</Toolbar>
		</AppBar>
	)
}

export default Navbar
