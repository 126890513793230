import React, { useMemo, useState } from 'react'
import {
	Box,
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	useTheme,
} from '@mui/material'
import Header from 'components/Header'
import { ResponsiveLine } from '@nivo/line'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { useGetChartTransactionsQuery } from 'slices/client/clientApiSlice'
import { format, isBefore, isAfter, subMonths } from 'date-fns'
import FlexBetween from 'components/FlexBetween'
import useAuth from 'hooks/useAuth'

const Daily = () => {
	const theme = useTheme()

	const [startDate, setStartDate] = useState(subMonths(new Date(), 7))
	const [endDate, setEndDate] = useState(new Date())
	const [view, setView] = useState('contribution')

	const { branch } = useAuth()

	const { data: transactions, isLoading: isLoadingTransactions } =
		useGetChartTransactionsQuery(branch)

	const [formattedData] = useMemo(() => {
		if (!transactions || isLoadingTransactions) return []
		let monthlyContribution = {}
		let monthlyWithdrawal = {}

		transactions.forEach((transaction) => {
			const date = new Date(transaction.creationDate)
			const year = format(date, 'yyyy')
			const month = format(date, 'MM')
			const monthYear = `${year}-${month}`
			if (transaction.type === 'CONTRIBUTION') {
				if (monthlyContribution[monthYear]) {
					monthlyContribution[monthYear] += transaction.amount
				} else {
					monthlyContribution[monthYear] = transaction.amount
				}
			} else if (transaction.type === 'WITHDRAWAL') {
				if (monthlyWithdrawal[monthYear]) {
					monthlyWithdrawal[monthYear] += Math.abs(transaction.amount)
				} else {
					monthlyWithdrawal[monthYear] = Math.abs(transaction.amount)
				}
			}
		})

		const totalContributionLine = {
			id: 'totalContribution',
			color: '#64e858',
			data: [],
		}
		const totalWithdrawalLine = {
			id: 'totalWithdrawal',
			color: '#FF5722',
			data: [],
		}

		const monthlyContributionEntries = Object.entries(monthlyContribution)
		for (let i = monthlyContributionEntries.length - 1; i >= 0; i--) {
			const [dateString, amount] = monthlyContributionEntries[i]
			const dateFormatted = new Date(dateString)
			if (
				isBefore(dateFormatted, startDate) ||
				isAfter(dateFormatted, endDate)
			) {
				continue
			}

			totalContributionLine.data = [
				...totalContributionLine.data,
				{ x: dateString, y: amount },
			]
		}

		const monthlyWithdrawalEntries = Object.entries(monthlyWithdrawal)
		for (let i = monthlyWithdrawalEntries.length - 1; i >= 0; i--) {
			const [dateString, amount] = monthlyWithdrawalEntries[i]
			const dateFormatted = new Date(dateString)

			if (
				isBefore(dateFormatted, startDate) ||
				isAfter(dateFormatted, endDate)
			) {
				continue
			}

			totalWithdrawalLine.data = [
				...totalWithdrawalLine.data,
				{ x: dateString, y: amount },
			]
		}
		const formattedData = [totalContributionLine, totalWithdrawalLine]
		return [formattedData]
	}, [transactions, startDate, endDate]) // eslint-disable-line react-hooks/exhaustive-deps

	if (!transactions || isLoadingTransactions) return 'Loading...'

	return (
		<Box m="1.5rem 2.5rem">
			<Header title="TRANSACTIONS BY MONTH" subtitle="Chart of transactions" />
			<Box height="75vh">
				<FlexBetween>
					<Box display="flex" justifyContent="flex-end">
						<div className="">
							<div>
								<InputLabel sx={{ mt: '1rem' }}>Start Date</InputLabel>

								<DatePicker
									selected={startDate}
									onChange={(date) => setStartDate(date)}
									selectsStart
									startDate={startDate}
									endDate={endDate}
									dateFormat="MM/yyyy"
									showMonthYearPicker
									className="text-black w-[100px] bg-slate-300 p-2 rounded flex justify-center items-center "
								/>
							</div>
							<div>
								<InputLabel sx={{ mt: '1rem' }}>End Date</InputLabel>

								<DatePicker
									selected={endDate}
									onChange={(date) => setEndDate(date)}
									selectsEnd
									startDate={startDate}
									endDate={endDate}
									dateFormat="MM/yyyy"
									showMonthYearPicker
									className="text-black w-[100px] bg-slate-300 p-2 rounded flex justify-center items-center "
								/>
							</div>
						</div>
					</Box>
				</FlexBetween>

				{transactions ? (
					<ResponsiveLine
						data={formattedData
						}
						theme={{
							axis: {
								domain: {
									line: {
										stroke: theme.palette.secondary[200],
									},
								},
								legend: {
									text: {
										fill: theme.palette.secondary[200],
									},
								},
								ticks: {
									line: {
										stroke: theme.palette.secondary[200],
										strokeWidth: 1,
									},
									text: {
										fill: theme.palette.secondary[200],
									},
								},
							},
							legends: {
								text: {
									fill: theme.palette.secondary[200],
								},
							},
							tooltip: {
								container: {
									color: theme.palette.primary.main,
								},
							},
						}}
						colors={{ datum: 'color' }}
						margin={{ top: 50, right: 50, bottom: 70, left: 60 }}
						xScale={{ type: 'point' }}
						yScale={{
							type: 'linear',
							min: 'auto',
							max: 'auto',
							stacked: false,
							reverse: false,
						}}
						yFormat=" >-.2f"
						curve="catmullRom"
						axisTop={null}
						axisRight={null}
						axisBottom={{
							orient: 'bottom',
							tickSize: 5,
							tickPadding: 5,
							tickRotation: 90,
							legend: 'Month',
							legendOffset: 60,
							legendPosition: 'middle',
						}}
						axisLeft={{
							orient: 'left',
							tickSize: 5,
							tickPadding: 5,
							tickRotation: 0,
							legend: 'Total',
							legendOffset: -50,
							legendPosition: 'middle',
						}}
						enableGridX={false}
						enableGridY={false}
						pointSize={10}
						pointColor={{ theme: 'background' }}
						pointBorderWidth={2}
						pointBorderColor={{ from: 'serieColor' }}
						pointLabelYOffset={-12}
						useMesh={true}
						legends={[
							{
								anchor: 'top-right',
								direction: 'column',
								justify: false,
								translateX: 50,
								translateY: 0,
								itemsSpacing: 0,
								itemDirection: 'left-to-right',
								itemWidth: 80,
								itemHeight: 20,
								itemOpacity: 0.75,
								symbolSize: 12,
								symbolShape: 'circle',
								symbolBorderColor: 'rgba(0, 0, 0, .5)',
								effects: [
									{
										on: 'hover',
										style: {
											itemBackground: 'rgba(0, 0, 0, .03)',
											itemOpacity: 1,
										},
									},
								],
							},
						]}
					/>
				) : (
					<>Loading...</>
				)}
			</Box>
		</Box>
	)
}

export default Daily
