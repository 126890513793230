import { createSelector, createEntityAdapter } from '@reduxjs/toolkit'
import { apiSlice } from 'app/api/apiSlice'

const reportsAdapter = createEntityAdapter()

const initialState = reportsAdapter.getInitialState()

export const reportsApiSlice = apiSlice.injectEndpoints({
	//post data to server and get back report data
	endpoints: (builder) => ({
		consolidatedReportData: builder.mutation({
			query: (data) => ({
				url: '/reports',
				method: 'POST',
				body: { ...data },
			}),
			invalidatesTags: [{ type: 'Reports', id: 'LIST' }],
		}),
		groupTransactionReportData: builder.mutation({
			query: (data) => ({
				url: '/reports/group',
				method: 'POST',
				body: { ...data },
			}),
			invalidatesTags: [{ type: 'Reports', id: 'LIST' }],
		}),
		individualTransactionReportData: builder.mutation({
			query: (data) => ({
				url: '/reports/individual',
				method: 'POST',
				body: { ...data },
			}),
			invalidatesTags: [{ type: 'Reports', id: 'LIST' }],
		}),
	}),
})

export const {
	useConsolidatedReportDataMutation,
	useGroupTransactionReportDataMutation,
	useIndividualTransactionReportDataMutation,
} = reportsApiSlice

export const {
	selectAll: selectAllReports,
	selectById: selectReportById,
	selectIds: selectReportIds,
} = reportsAdapter.getSelectors((state) => state.reports)

export default reportsApiSlice.reducer
