import { apiSlice } from "app/api/apiSlice";

export const campaignsApiSlice = apiSlice.injectEndpoints({
  endpoints: (build, pageLength, pageNumber) => ({
    getCampaigns: build.mutation({
      query: (branch) => ({
        url: `/campaign/${branch}`,
        method: "POST",
        body: { pageLength, pageNumber },
      }),
      providesTags: ["Campaign"],
    }),
    getCampaignMembers: build.query({
      query: (userId) => ({
        url: "campaign/members",
        method: "POST",
        body: {
          ...userId,
        },
      }),
    }),
    getCampaignsNumber: build.query({
      query: (branch) => ({
        url: `campaign/number/${branch}`,
        method: "GET",
      }),
    }),
    getCampaignTransactions: build.query({
      query: (userId) => ({
        url: "campaign/transactions",
        method: "POST",
        body: {
          ...userId,
        },
      }),
    }),
    getCampaignApprovers: build.query({
      query: (userId) => ({
        url: "campaign/approvers",
        method: "POST",
        body: {
          ...userId,
        },
      }),
    }),
  }),
});

export const {
  useGetCampaignsMutation,
  useGetCampaignApproversQuery,
  useGetCampaignMembersQuery,
  useGetCampaignTransactionsQuery,
  useGetCampaignsNumberQuery,
} = campaignsApiSlice;
