import React, { useMemo, useState } from 'react'
import {
	Box,
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	useTheme,
} from '@mui/material'
import Header from 'components/Header'
import { ResponsiveLine } from '@nivo/line'
import 'react-datepicker/dist/react-datepicker.css'
import { useGetChartTransactionsQuery } from 'slices/client/clientApiSlice'
import { format, getMonth, getYear } from 'date-fns'
import ReactDatePicker from 'react-datepicker'
import { useDispatch, useSelector } from 'react-redux'
import { selectYearMonth, setYearMonth } from 'state'
import CircularProgress from '@mui/material/CircularProgress'

const Branches = () => {
	const [branch, setBranch] = useState('Main')
	const theme = useTheme()
	const [startDate, setStartDate] = useState(new Date())
	const dispatch = useDispatch()
	const yearSelected = useSelector(selectYearMonth)

	const handle = (date) => {
		setStartDate(date)
		dispatch(setYearMonth(date.getFullYear()))
	}

	const { data: transactions, isLoading: isLoadingTransactions } =
		useGetChartTransactionsQuery(branch)

	const [formattedData] = useMemo(() => {
		if (!transactions || isLoadingTransactions) return []

		let monthContributions = {}
		let monthWithdrawals = {}

		transactions.forEach((transaction) => {
			const date = new Date(transaction.creationDate)
			const month = format(date, 'MMMM')
			const year = getYear(date)
			if (transaction.type === 'CONTRIBUTION' && year === yearSelected) {
				if (monthContributions[month]) {
					monthContributions[month] += transaction.amount
				} else {
					monthContributions[month] = transaction.amount
				}
			} else if (transaction.type === 'WITHDRAWAL' && year === yearSelected) {
				if (monthWithdrawals[month]) {
					monthWithdrawals[month] += transaction.amount
				} else {
					monthWithdrawals[month] = transaction.amount
				}
			}
		})

		//sort the monthContributions object by calendar month
		let sortedMonthAmount = {}

		Object.keys(monthContributions)
			.sort((a, b) => {
				return (
					getMonth(new Date(`01 ${a} ${yearSelected}`)) -
					getMonth(new Date(`01 ${b} ${yearSelected}`))
				)
			})
			.forEach((key) => {
				sortedMonthAmount[key] = monthContributions[key]
			})

		let sortedMonthWithdrawals = {}

		Object.keys(monthWithdrawals)
			.sort((a, b) => {
				return (
					getMonth(new Date(`01 ${a} ${yearSelected}`)) -
					getMonth(new Date(`01 ${b} ${yearSelected}`))
				)
			})
			.forEach((key) => {
				sortedMonthWithdrawals[key] = monthWithdrawals[key]
			})

		const totalContributionsLine = {
			id: 'totalContributions',
			color: '#64e858',
			data: [],
		}

		const totalWithdrawalsLine = {
			id: 'totalWithdrawals',
			color: '#FF5722',
			data: [],
		}

		Object.entries(sortedMonthWithdrawals).forEach(([month, amount]) => {
			totalWithdrawalsLine.data = [
				...totalWithdrawalsLine.data,
				{ x: month, y: amount },
			]
		})

		Object.entries(sortedMonthAmount).forEach(([month, amount]) => {
			totalContributionsLine.data = [
				...totalContributionsLine.data,
				{ x: month, y: amount },
			]
		})

		const formattedData = [totalContributionsLine, totalWithdrawalsLine]
		return [formattedData]
	}, [transactions, yearSelected, branch]) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<Box m="1.5rem 2.5rem">
			<Header
				title="Branches"
				subtitle="Overview of Performance of all branches"
			/>
			<Box>
				<FormControl sx={{ mt: '1rem' }}>
					<InputLabel>Branch</InputLabel>
					<Select
						className="cursor-pointer"
						value={branch}
						label="Branch"
						onChange={(e) => setBranch(e.target.value)}>
						<MenuItem value={'Main'}>Head Quarters</MenuItem>
						<MenuItem value={'Petauke'}>Petauke</MenuItem>
						<MenuItem value={'Katete'}>Katete</MenuItem>
						<MenuItem value={'Lundazi'}>Lundazi</MenuItem>
						<MenuItem value={'Chipata'}>Chipata</MenuItem>
						<MenuItem value={'Mazabuka'}>Mazabuka</MenuItem>
						<MenuItem value={'Kafue'}>Kafue</MenuItem>
						<MenuItem value={'Choma'}>Choma</MenuItem>
						<MenuItem value={'Livingstone'}>Livingstone</MenuItem>
						<MenuItem value={'Kasama'}>Kasama</MenuItem>
						<MenuItem value={'Sesheke'}>Sesheke</MenuItem>
						<MenuItem value={'Mpika'}>Mpika</MenuItem>
						<MenuItem value={'Kapiri'}>Kapiri</MenuItem>
					</Select>
				</FormControl>
				<InputLabel sx={{ mt: '1rem' }}>Select Year</InputLabel>
				<ReactDatePicker
					selected={startDate}
					onChange={(date) => handle(date)}
					showYearPicker
					dateFormat="yyyy"
					yearItemNumber={9}
					label="Select Year"
					className="text-black w-[130px] flex justify-center items-center mt-2 border cursor-pointer border-gray-300 rounded-md shadow-sm py-2 px-4 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
				/>
			</Box>
			<Box height="75vh">
				{isLoadingTransactions ? (
					<div className="flex justify-center items-center">
						<CircularProgress />
					</div>
				) : transactions ? (
					<ResponsiveLine
						data={formattedData}
						theme={{
							axis: {
								domain: {
									line: {
										stroke: theme.palette.secondary[200],
									},
								},
								legend: {
									text: {
										fill: theme.palette.secondary[200],
									},
								},
								ticks: {
									line: {
										stroke: theme.palette.secondary[200],
										strokeWidth: 1,
									},
									text: {
										fill: theme.palette.secondary[200],
									},
								},
							},
							legends: {
								text: {
									fill: theme.palette.secondary[200],
								},
							},
							tooltip: {
								container: {
									color: theme.palette.primary.main,
								},
							},
						}}
						colors={{ datum: 'color' }}
						margin={{ top: 50, right: 50, bottom: 70, left: 60 }}
						xScale={{ type: 'point' }}
						yScale={{
							type: 'linear',
							min: 'auto',
							max: 'auto',
							stacked: false,
							reverse: false,
						}}
						yFormat=" >-.2f"
						curve="catmullRom"
						axisTop={null}
						axisRight={null}
						axisBottom={{
							orient: 'bottom',
							tickSize: 5,
							tickPadding: 5,
							tickRotation: 90,
							legend: 'Month',
							legendOffset: 60,
							legendPosition: 'middle',
						}}
						axisLeft={{
							orient: 'left',
							tickSize: 5,
							tickPadding: 5,
							tickRotation: 0,
							legend: 'Total',
							legendOffset: -50,
							legendPosition: 'middle',
						}}
						enableGridX={false}
						enableGridY={false}
						pointSize={10}
						pointColor={{ theme: 'background' }}
						pointBorderWidth={2}
						pointBorderColor={{ from: 'serieColor' }}
						pointLabelYOffset={-12}
						useMesh={true}
						legends={[
							{
								anchor: 'top-right',
								direction: 'column',
								justify: false,
								translateX: 50,
								translateY: 0,
								itemsSpacing: 0,
								itemDirection: 'left-to-right',
								itemWidth: 80,
								itemHeight: 20,
								itemOpacity: 0.75,
								symbolSize: 12,
								symbolShape: 'circle',
								symbolBorderColor: 'rgba(0, 0, 0, .5)',
								effects: [
									{
										on: 'hover',
										style: {
											itemBackground: 'rgba(0, 0, 0, .03)',
											itemOpacity: 1,
										},
									},
								],
							},
						]}
					/>
				) : (
					<div className="flex justify-center items-center ">
						<CircularProgress />
					</div>
				)}
			</Box>
		</Box>
	)
}

export default Branches
