import { useStateContext } from "contexts/ContextProvider";
import React, { useState } from "react";
import { format } from "date-fns";
import { DataGrid } from "@mui/x-data-grid";
import { Box, useTheme } from "@mui/material";
import axios from "axios";
import { useEffect } from "react";

function Approvers() {
  const theme = useTheme();
  const [pageSize, setPageSize] = React.useState(10);
  const [campaignApprovers, setCampaignApprovers] = useState({});
  const { campaignData } = useStateContext();
  const columns = [
    { field: "name", headerName: "Name", flex: 1 },
    { field: "username", headerName: "phoneNumber", flex: 0.5 },
    { field: "status", headerName: "Status", flex: 0.5 },
    { field: "role", headerName: "Role", flex: 1 },
    {
      field: "creationDate",
      headerName: "Creation Date",
      flex: 1,
      renderCell: (params) => format(new Date(params.value), "dd/MM/yyyy"),
    },
  ];
  //------------------------------------------------------------------------------------------------
  //New code here
  const instance = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    headers: { "Content-Type": "application/json", Accept: "application/json" },
  });

  useEffect(() => {
    const getCampaignApprovers = async () => {
      try {
        const { data } = await instance.post(
          "/campaign/approvers",
          campaignData
        );
        setCampaignApprovers(data);
        console.log(campaignData);
        return;
      } catch (error) {
        throw new Error(error);
      }
    };
    getCampaignApprovers();
  }, [campaignData]);
  //------------------------------------------------------------------------------------------------
  return (
    <Box
      mt="0px"
      height="50vh"
      sx={{
        "& .MuiDataGrid-root": { border: "none" },
        "& .MuiDataGrid-cell": { borderBottom: "none" },
        "& .MuiDataGrid-columnHeaders": {
          backgroundColor: theme.palette.background.alt,
          color: theme.palette.secondary[100],
          borderBottom: "none",
        },
        "& .MuiDataGrid-virtualScroller": {
          backgroundColor: theme.palette.primary.light,
        },
        "& .MuiDataGrid-footercontainer": {
          backgroundColor: theme.palette.background.alt,
          color: theme.palette.secondary[100],
          borderTop: "none",
        },
        "& .MuiDataGrid-toolbatContainer .MuiButton-text": {
          color: `${theme.palette.secondary[200]} !important`,
        },
      }}
    >
      <DataGrid
        // loading={isLoading || !data}
        getRowId={(row) => row.id}
        rows={campaignApprovers || []}
        columns={columns}
        initialState={{
          sorting: {
            sortModel: [{ field: "creationDate", sort: "desc" }],
          },
        }}
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        rowsPerPageOptions={[10, 20, 50]}
        pagination
      />
    </Box>
  );
}

export default Approvers;
