import { apiSlice } from 'app/api/apiSlice'

export const clientApiSlice = apiSlice.injectEndpoints({
	endpoints: (build) => ({
		getTransactions: build.query({
			query: (branch) => ({
				url: `client/transactions/${branch}`,
				method: 'GET',
			}),
		}),
		getChartTransactions: build.query({
			query: (branch) => ({
				url: `client/transactionsByBranch/${branch}`,
				method: 'GET',
			}),
		}),
	}),
})

export const {
	useGetProductsQuery,
	useGetTransactionsQuery,
	useGetChartTransactionsQuery,
} = clientApiSlice
