import React, { useMemo } from 'react'
import { Box, useTheme } from '@mui/material'
import { ResponsiveLine } from '@nivo/line'
import 'react-datepicker/dist/react-datepicker.css'
import useAuth from 'hooks/useAuth'
import { useGetChartTransactionsQuery } from 'slices/client/clientApiSlice'

const ContributionsChart = (isDashboard = false) => {
	const theme = useTheme()

	const { branch } = useAuth()
	const { data: contributions, isLoading } =
		useGetChartTransactionsQuery(branch)
	console.log(
		'🚀 ~ file: ContributionsChart.jsx:13 ~ ContributionsChart ~ contributions',
		contributions,
	)

	const [totalContributionsLine] = useMemo(() => {
		if (!contributions) return []

		let monthContributions = {}
		const currentDate = new Date()
		const currentYear = currentDate.getFullYear()
		const currentMonthIndex = currentDate.getMonth()

		contributions.forEach((contribution) => {
			const contributionDate = new Date(contribution.creationDate)
			const contributionYear = contributionDate.getFullYear()
			const contributionMonthIndex = contributionDate.getMonth()

			if (contribution.type === 'CONTRIBUTION') {
				if (
					contributionYear >=
					currentYear - 1
					// contributionMonthIndex >= currentMonthIndex - 11 &&
					// contributionMonthIndex <= currentMonthIndex
				) {
					const month = contributionDate.toLocaleString('default', {
						month: 'long',
					})
					const year = contributionYear.toString()
					const key = `${month} ${year}`

					if (monthContributions[key]) {
						monthContributions[key] += contribution.amount
					} else {
						monthContributions[key] = contribution.amount
					}
				}
			}
		})

		let sortedMonthContributions = {}

		Object.keys(monthContributions)
			.sort((a, b) => {
				return new Date(`01 ${a}`) - new Date(`01 ${b}`)
			})
			.forEach((key) => {
				sortedMonthContributions[key] = monthContributions[key]
			})

		const totalContributionsLine = {
			id: 'totalContributions',
			color: '#64e858',
			data: [],
		}

		Object.keys(sortedMonthContributions).forEach((monthYear) => {
			totalContributionsLine.data.push({
				x: monthYear,
				y: sortedMonthContributions[monthYear],
			})
		})

		return [[totalContributionsLine]]
	}, [contributions])

	if (isLoading || !contributions) return <Box>Loading...</Box>

	return (
		<ResponsiveLine
			data={totalContributionsLine}
			theme={{
				axis: {
					domain: {
						line: {
							stroke: theme.palette.secondary[200],
						},
					},
					legend: {
						text: {
							fill: theme.palette.secondary[200],
						},
					},
					ticks: {
						line: {
							stroke: theme.palette.secondary[200],
							strokeWidth: 1,
						},
						text: {
							fill: theme.palette.secondary[200],
						},
					},
				},
				legends: {
					text: {
						fill: theme.palette.secondary[300],
					},
				},
				tooltip: {
					container: {
						color: theme.palette.primary.main,
					},
				},
			}}
			colors={{ datum: 'color' }}
			margin={{ top: 20, right: 50, bottom: 50, left: 70 }}
			xScale={{ type: 'point' }}
			yScale={{
				type: 'linear',
				min: 'auto',
				max: 'auto',
				stacked: false,
				reverse: false,
			}}
			yFormat=" >-.2f"
			curve="catmullRom"
			enableArea={isDashboard}
			axisTop={null}
			axisRight={null}
			axisBottom={{
				format: (v) => {
					if (isDashboard) return v.slice(0, 3)
					return v
				},
				orient: 'bottom',
				tickSize: 5,
				tickPadding: 5,
				tickRotation: 0,
				legend: isDashboard ? '' : 'Month',
				legendOffset: 36,
				legendPosition: 'middle',
			}}
			axisLeft={{
				orient: 'left',
				tickValues: 5,
				tickSize: 5,
				tickPadding: 5,
				tickRotation: 0,
				legend: isDashboard
					? ''
					: `Total ${'totalContributions' === 'totalContributions'} for Year`,
				legendOffset: -60,
				legendPosition: 'middle',
			}}
			enableGridX={false}
			enableGridY={false}
			pointSize={10}
			pointColor={{ theme: 'background' }}
			pointBorderWidth={2}
			pointBorderColor={{ from: 'serieColor' }}
			pointLabelYOffset={-12}
			useMesh={true}
			legends={
				isDashboard
					? [
							{
								anchor: 'bottom-right',
								direction: 'column',
								justify: false,
								translateX: 30,
								translateY: -200,
								itemsSpacing: 0,
								itemDirection: 'left-to-right',
								itemWidth: 80,
								itemHeight: 20,
								itemOpacity: 0.75,
								symbolSize: 12,
								symbolShape: 'circle',
								symbolBorderColor: 'rgba(0, 0, 0, .5)',
								effects: [
									{
										on: 'hover',
										style: {
											itemBackground: 'rgba(0, 0, 0, .03)',
											itemOpacity: 1,
										},
									},
								],
							},
					  ]
					: undefined
			}
		/>
	)
}

export default ContributionsChart
