import React, { Fragment } from "react";
import { format, compareAsc, parseISO } from "date-fns";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

const borderColor = "#3778C2";
const styles = StyleSheet.create({
  row: {
    flexDirection: "row",
    borderBottomColor: "#3778C2",
    borderBottomWidth: 1,
    alignItems: "center",
    height: 24,
    fontStyle: "bold",
  },
  date: {
    width: "20%",
    textAlign: "left",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    paddingLeft: 8,
  },
  savings: {
    width: "20%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: "right",
    paddingRight: 8,
  },
  withdraws: {
    width: "20%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: "right",
    paddingRight: 8,
  },
  paybacks: {
    width: "20%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: "right",
    paddingRight: 8,
  },
  balance: {
    width: "20%",
    textAlign: "right",
    paddingRight: 8,
  },
});

const ReportPDFTableRow = ({ items }) => {
  const transactionPurposeSeparator = (value, item) => {
    if (item.type === "WITHDRAWAL" && value === "WITHDRAWAL") {
      return item.amount;
    }
    if (item.purpose === value) {
      return item.amount;
    } else {
      return 0;
    }
  };
  const rows = items?.map((item) => (
    <View style={styles.row} key={item.date}>
      {/* <View style={styles.row} key={item.sno.toString()}> */}
      <Text style={styles.date}>
        {format(parseISO(item.date), "dd-MM-yyyy HH:mm")}
      </Text>
      <Text style={styles.savings}>
        {transactionPurposeSeparator("SAVINGS", item)}
      </Text>
      <Text style={styles.withdraws}>
        {transactionPurposeSeparator("WITHDRAWAL", item)}
      </Text>
      {/* <Text style={styles.paybacks}>{(item.qty * item.rate).toFixed(2)}</Text> */}
      <Text style={styles.paybacks}>
        {transactionPurposeSeparator("REPAYMENT", item)}
      </Text>
      <Text style={styles.balance}>{item.balance}</Text>
    </View>
  ));
  return <Fragment>{rows}</Fragment>;
};

export default ReportPDFTableRow;
