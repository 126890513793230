import { useSelector } from "react-redux";
import { selectCurrentToken } from "slices/auth/authSlice";
import jwtDecode from "jwt-decode";

const useAuth = () => {
  const token = useSelector(selectCurrentToken);
  let isSuperAdmin = false;
  let isBranchManager = false;
  let status = "";

  if (token) {
    const decodedToken = jwtDecode(token);
    const { username, roles, branch } = decodedToken.UserInfo;

    isSuperAdmin = roles.includes("SuperAdmin");
    isBranchManager = roles.includes("BranchManager");

    if (isSuperAdmin) status = "SuperAdmin";
    if (isBranchManager) status = "BranchManager";
    console.log("useAuth: ", {
      username,
      roles,
      status,
      isSuperAdmin,
      isBranchManager,
      branch,
    });
    return { username, roles, status, isSuperAdmin, isBranchManager, branch };
  }

  return { username: "", roles: [], isSuperAdmin, isBranchManager, status };
};

export default useAuth;
