import React, { useEffect, useRef, useState } from 'react'
import BeatLoader from 'react-spinners/BeatLoader'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useLoginMutation } from 'slices/auth/authApiSlice'
import { setCredentials } from 'slices/auth/authSlice'
import usePersist from 'hooks/usePersist'
import { useTheme } from '@mui/system'

const Login = () => {
	const theme = useTheme()

	const userRef = useRef()
	const errRef = useRef()

	const [persist, setPersist] = usePersist()

	const [username, setUsername] = useState('')
	const [password, setPassword] = useState('')
	const [errMsg, setErrMsg] = useState('')

	const navigate = useNavigate()
	const dispatch = useDispatch()

	const [login, { isLoading }] = useLoginMutation()

	useEffect(() => {
		userRef.current.focus()
	}, [])

	useEffect(() => {
		setErrMsg('')
	}, [username, password])

	const handleSubmit = async (e) => {
		e.preventDefault()
		try {
			const { accessToken } = await login({ username, password }).unwrap()
			dispatch(setCredentials({ accessToken }))
			setUsername('')
			setPassword('')
			navigate(`/dashboard`)
		} catch (err) {
			if (!err.status) {
				setErrMsg('No Server Response')
			} else if (err.status === 400) {
				setErrMsg('Missing Username or Password')
			} else if (err.status === 401) {
				setErrMsg('Unauthorized')
			} else {
				setErrMsg(err.data?.message)
			}
			errRef.current.focus()
		}
	}

	const handleUserInput = (e) => {
		setUsername(e.target.value)
	}

	const handlePasswordInput = (e) => {
		setPassword(e.target.value)
	}

	const handleToggle = () => {
		setPersist((prev) => !prev)
	}
	const errClass = errMsg ? 'errmsg' : 'offscreen'

	return (
		<div className="w-full p-20 z-10 m-auto bg-white rounded-md shadow-md lg:max-w-xl">
			<h1 className="text-3xl font-semibold text-center text-purple-700 underline">
				Sign in
			</h1>
			<p ref={errRef} className={errClass} aria-live="assertive">
				{errMsg}
			</p>
			<form onSubmit={handleSubmit} className="mt-6">
				<div className="mb-2">
					<label
						htmlFor="username"
						className="block text-sm font-semibold text-gray-800">
						Username
					</label>
					<input
						type="text"
						id="username"
						ref={userRef}
						onChange={handleUserInput}
						autoComplete="off"
						required
						value={username}
						className="block w-full px-4 py-2 mt-2 text-purple-700 bg-white border rounded-md focus:border-purple-400 focus:ring-purple-300 focus:outline-none focus:ring focus:ring-opacity-40"
					/>
				</div>
				<div className="mb-2">
					<label
						htmlFor="password"
						className="block text-sm font-semibold text-gray-800">
						Password
					</label>
					<input
						id="password"
						onChange={handlePasswordInput}
						required
						value={password}
						type="password"
						className="block w-full px-4 py-2 mt-2 text-purple-700 bg-white border rounded-md focus:border-purple-400 focus:ring-purple-300 focus:outline-none focus:ring focus:ring-opacity-40"
					/>
				</div>
				<div className="flex justify-between items-center mb-6">
					<div className="form-group form-check">
						<input
							type="checkbox"
							className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
							id="persist"
							onChange={handleToggle}
							checked={persist}
						/>
						<label
							className="form-check-label inline-block text-gray-800"
							htmlFor="persist">
							Remember me
						</label>
					</div>
				</div>
				<div className="mt-6">
					<button
						className="w-full px-4 py-2 tracking-wide text-white transition-colors duration-200 transform  rounded-md  focus:outline-none"
						style={{ background: theme.palette.secondary[300] }}>
						{isLoading ? <BeatLoader color="white" /> : 'Sign in'}
					</button>
				</div>
			</form>
		</div>
	)
}

export default Login
