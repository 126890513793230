import FlexBetween from 'components/FlexBetween'
import Header from 'components/Header'
import { DownloadOutlined, Groups2Outlined } from '@mui/icons-material'
import { Box, Button, useTheme, useMediaQuery } from '@mui/material'
import StatBox from 'components/StatBox'
import {
	useGetCampaignsMutation,
	useGetCampaignsQuery,
	useGetCampaignsNumberQuery,
} from 'slices/campaigns/campaignsApiSlice'
import { useGetChartUsersQuery } from 'slices/users/usersApiSlice'
import { useNavigate } from 'react-router-dom'
import MembersChart from 'components/MembersChart'
import useAuth from 'hooks/useAuth'
import WithdrawalsChart from 'components/WithdrawalsChart'
import ContributionsChart from 'components/ContributionsChart'

const Dashboard = () => {
	const theme = useTheme()
	const isNonMediumScreens = useMediaQuery('(min-width: 1200px)')

	const navigate = useNavigate()

	const { branch } = useAuth()

	const { data: campaigns, isLoading: isCampaignsLoading } =
		useGetCampaignsMutation()
	//write a query to get number of campaigns by branch
	const { data: campaignsCount, isLoading: isCampaignsCountLoading } =
		useGetCampaignsNumberQuery(branch)

	const { data: users, isLoading: isUsersLoading } =
		useGetChartUsersQuery(branch)

	return (
		<Box m="1.5rem 2.5rem">
			<FlexBetween>
				<Header title="DASHBOARD" subtitle="Welcome to your dashboard" />

				<Box>
					<Button
						onClick={() => navigate('/reports')}
						sx={{
							backgroundColor: theme.palette.secondary.light,
							color: theme.palette.background.alt,
							fontSize: '14px',
							fontWeight: 'bold',
							padding: '10px 20px',
						}}>
						<DownloadOutlined sx={{ mr: '10px' }} />
						Download Reports
					</Button>
				</Box>
			</FlexBetween>

			<Box
				mt="20px"
				display="grid"
				gridTemplateColumns="repeat(12, 1fr)"
				gridAutoRows="160px"
				gap="20px"
				sx={{
					'& > div': { gridColumn: isNonMediumScreens ? undefined : 'span 12' },
				}}>
				{/* ROW 1 */}
				<StatBox
					title="Total Groups"
					value={campaignsCount && campaignsCount.count}
					icon={
						<Groups2Outlined
							sx={{ color: theme.palette.secondary[300], fontSize: '26px' }}
						/>
					}
				/>
				<StatBox
					title="Total Members"
					value={users && users.length}
					icon={
						<Groups2Outlined
							sx={{ color: theme.palette.secondary[300], fontSize: '26px' }}
						/>
					}
				/>
				<Box
					gridColumn="span 8"
					gridRow="span 2"
					backgroundColor={theme.palette.background.alt}
					p="1rem"
					borderRadius="0.55rem">
					{/* <OverviewChart view="sales" isDashboard={true} /> */}
					<MembersChart isDashboard={true} />
				</Box>
				{/* <StatBox
					title="Monthly Sales"
					value={data && data.thisMonthStats.totalSales}
					icon={
						<PersonAdd
							sx={{ color: theme.palette.secondary[300], fontSize: '26px' }}
						/>
					}
				/>
				<StatBox
					title="Yearly Sales"
					value={data && data.yearlySalesTotal}
					increase="+43%"
					description="Since last month"
					icon={
						<Traffic
							sx={{ color: theme.palette.secondary[300], fontSize: '26px' }}
						/>
					}
				/>  */}

				{/* ROW 2 */}
				<Box
					gridColumn="span 6"
					gridRow="span 2"
					backgroundColor={theme.palette.background.alt}
					p="1rem"
					borderRadius="0.55rem">
					<WithdrawalsChart isDashboard={true} />
				</Box>
				<Box
					gridColumn="span 6"
					gridRow="span 2"
					backgroundColor={theme.palette.background.alt}
					p="1rem"
					borderRadius="0.55rem">
					<ContributionsChart isDashboard={true} />
				</Box>
				{/*
        <Box
          gridColumn="span 4"
          gridRow="span 3"
          backgroundColor={theme.palette.background.alt}
          p="1.5rem"
          borderRadius="0.55rem"
        >
          <Typography variant="h6" sx={{ color: theme.palette.secondary[100] }}>
            Transactions By Purpose
          </Typography>
          <BreakdownChart isDashboard={true} />
          <Typography
            p="0 0.6rem"
            fontSize="0.8rem"
            sx={{ color: theme.palette.secondary[200] }}
          >
            Breakdown of all transactions made.
          </Typography>
        </Box> */}
			</Box>
		</Box>
	)
}

export default Dashboard
