import React, { useState, useEffect } from "react";
import {
  Box,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemButton,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material";

import {
  SettingsOutlined,
  ChevronLeft,
  ChevronRightOutlined,
  HomeOutlined,
  Groups2Outlined,
  ReceiptLongOutlined,
  PointOfSaleOutlined,
  TodayOutlined,
  CalendarMonthOutlined,
  AdminPanelSettingsOutlined,
  PieChartOutlineOutlined,
  ReportOutlined,
  Person2Outlined,
  FmdGoodOutlined,
} from "@mui/icons-material";

import { useLocation, useNavigate } from "react-router-dom";
import FlexBetween from "./FlexBetween";
import ProfileImage from "assets/user.png";
import { useSelector } from "react-redux";
import { selectMode } from "state";
import LogoWhitePng from "assets/microloan-logo.png";
import LogoPng from "assets/microloan-logo-light.png";
import useAuth from "hooks/useAuth";

const Sidebar = ({
  drawerWidth,
  isSidebarOpen,
  setIsSidebarOpen,
  isNonMobile,
}) => {
  const { pathname } = useLocation();
  const [active, setActive] = useState("");
  const navigate = useNavigate();
  const theme = useTheme();
  const mode = useSelector(selectMode);

  const { username, roles, isSuperAdmin } = useAuth();

  const navItems = [
    {
      text: "Dashboard",
      icon: <HomeOutlined />,
    },
    // {
    //   text: "Client Facing",
    //   icon: null,
    // },

    {
      text: "Groups",
      icon: <Groups2Outlined />,
    },
    {
      text: "Members",
      icon: <Person2Outlined />,
    },
    {
      text: "Transactions",
      icon: <ReceiptLongOutlined />,
    },
    {
      text: "Analytics",
      icon: <CalendarMonthOutlined />,
    },
    // isSuperAdmin && {
    //   text: "Overview",
    //   icon: <PointOfSaleOutlined />,
    // },
    // isSuperAdmin && {
    //   text: "Daily",
    //   icon: <TodayOutlined />,
    // },
    // isSuperAdmin && {
    //   text: "Monthly",
    //   icon: <CalendarMonthOutlined />,
    // },
    isSuperAdmin && {
      text: "Branches",
      icon: <FmdGoodOutlined />,
    },
    {
      text: "Statements",
      icon: <ReportOutlined />,
    },
    isSuperAdmin && {
      text: "Managment",
      icon: null,
    },
    isSuperAdmin && {
      text: "Admin",
      icon: <AdminPanelSettingsOutlined />,
    },
  ];

  useEffect(() => {
    setActive(pathname.substring(1));
  }, [pathname]);

  return (
    <Box component="nav">
      {isSidebarOpen && (
        <Drawer
          open={isSidebarOpen}
          onClose={() => setIsSidebarOpen(false)}
          variant="persistent"
          anchor="left"
          sx={{
            width: drawerWidth,
            "& .MuiDrawer-paper": {
              color: theme.palette.secondary[200],
              backgroundColor: theme.palette.background.alt,
              boxSixing: "border-box",
              borderWidth: isNonMobile ? 0 : "2px",
              width: drawerWidth,
            },
          }}
        >
          <Box width="100%">
            <Box m="0.5rem 1rem 1rem 1.2rem">
              <FlexBetween color={theme.palette.secondary.main}>
                <Box display="flex" alignItems="center" gap="0.5rem">
                  <Box
                    component="img"
                    alt="logo"
                    src={mode === "dark" ? LogoWhitePng : LogoPng}
                    height="95px"
                    width="190px"
                    sx={{ objectFit: "contain" }}
                  />
                </Box>
                {!isNonMobile && (
                  <IconButton onClick={() => setIsSidebarOpen(!isSidebarOpen)}>
                    <ChevronLeft />
                  </IconButton>
                )}
              </FlexBetween>
            </Box>
            <List>
              {navItems.map(({ text, icon }) => {
                if (!icon) {
                  return (
                    <Typography key={text} sx={{ m: "2.25rem 0 1rem 3rem" }}>
                      {text}
                    </Typography>
                  );
                }
                const lcText = text.toLowerCase();
                return (
                  <ListItem key={text} disablePadding>
                    <ListItemButton
                      onClick={() => {
                        navigate(`/${lcText}`);
                        setActive(active);
                      }}
                      sx={{
                        borderRadius: "0.5rem",
                        backgroundColor:
                          active === lcText
                            ? theme.palette.secondary[300]
                            : "transparent",

                        color:
                          active === lcText
                            ? "white"
                            : theme.palette.secondary[100],
                        "&:hover": {
                          color: theme.palette.secondary[100],
                        },
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          ml: "2rem",
                          color:
                            active === lcText
                              ? "white"
                              : theme.palette.secondary[200],
                        }}
                      >
                        {icon}
                      </ListItemIcon>
                      <ListItemText primary={text} />
                      {active === lcText && (
                        <ChevronRightOutlined sx={{ ml: "auto" }} />
                      )}
                    </ListItemButton>
                  </ListItem>
                );
              })}
            </List>
          </Box>
          <Box position="absolute " bottom="2rem">
            <Divider />
            <FlexBetween textTransform="none" gap="1rem" m="1.5rem 2rem 0 3rem">
              <Box
                component="img"
                alt="profile"
                src={ProfileImage}
                height="40px"
                width="40px"
                borderRadius="50%"
                sx={{ objectFit: "cover" }}
              />
              <Box textAlign="left">
                <Typography
                  fontSize="0.9rem"
                  fontWeight="bold"
                  sx={{ color: theme.palette.secondary[100] }}
                >
                  {username}
                </Typography>
                <Typography
                  fontSize="0.9rem"
                  fontWeight="bold"
                  sx={{ color: theme.palette.secondary[200] }}
                >
                  {roles}
                </Typography>
              </Box>
            </FlexBetween>
          </Box>
        </Drawer>
      )}
    </Box>
  );
};

export default Sidebar;
