import React, { createContext, useContext, useState } from "react";

const StateContext = createContext();

export const ContextProvider = ({ children }) => {
  const [memberModalOpen, setMemberModalOpen] = useState(false);
  const [memberData, setMemberData] = useState({});
  const [groupData, setGroupData] = useState({});
  const [openGroupModal, setOpenGroupModal] = useState(false);
  const [campaignModalOpen, setCampaignModalOpen] = useState(false);
  const [campaignData, setCampaignData] = useState({});

  return (
    <StateContext.Provider
      value={{
        openGroupModal,
        setOpenGroupModal,
        memberModalOpen,
        setMemberModalOpen,
        memberData,
        setMemberData,
        groupData,
        setGroupData,
        campaignModalOpen,
        setCampaignModalOpen,
        campaignData,
        setCampaignData,
      }}
    >
      {children}
    </StateContext.Provider>
  );
};

export const useStateContext = () => useContext(StateContext);
