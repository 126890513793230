import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  headerContainer: {
    marginTop: 36,
    justifyContent: "flex-start",
    width: "50%",
  },
  billTo: {
    marginTop: 20,
    paddingBottom: 3,
    fontFamily: "Helvetica-Oblique",
  },
});

const ReportInfo = ({ report }) => (
  <View style={styles.headerContainer}>
    <Text style={styles.billTo}>Information:</Text>
    <Text>PhoneNumber: {report.groupAdminNumber}</Text>
    <Text>Opening Balance: {report.openingBalance}</Text>
    <Text>Closing Balance: {report.closingBalance}</Text>
    <Text>Start Date: {report.startDate}</Text>
    <Text>End Date: {report.endDate}</Text>
  </View>
);

export default ReportInfo;
