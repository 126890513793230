import React, { useMemo } from 'react'
import { Box, useTheme } from '@mui/material'
import { ResponsiveLine } from '@nivo/line'
import 'react-datepicker/dist/react-datepicker.css'
import { useGetChartUsersQuery } from 'slices/users/usersApiSlice'
import useAuth from 'hooks/useAuth'

const MembersChart = (isDashboard = false) => {
	const theme = useTheme()

	const { branch } = useAuth()
	const { data: members, isLoading } = useGetChartUsersQuery(branch)
	console.log(
		'🚀 ~ file: MembersChart.jsx:13 ~ MembersChart ~ members',
		members,
	)

	const [totalMembersLine] = useMemo(() => {
		if (!members) return []

		let monthMembers = {}
		const currentDate = new Date()
		const currentYear = currentDate.getFullYear()
		const currentMonthIndex = currentDate.getMonth()

		members.forEach((member) => {
			const memberDate = new Date(member.creationDate)
			const memberYear = memberDate.getFullYear()
			const memberMonthIndex = memberDate.getMonth()

			if (
				memberYear >=
				currentYear - 1
				// memberMonthIndex >= currentMonthIndex - 11 &&
				// memberMonthIndex <= currentMonthIndex
			) {
				const month = memberDate.toLocaleString('default', { month: 'long' })
				const year = memberYear.toString()
				const key = `${month} ${year}`

				if (monthMembers[key]) {
					monthMembers[key] += 1
				} else {
					monthMembers[key] = 1
				}
			}
		})

		let sortedMonthMembers = {}

		Object.keys(monthMembers)
			.sort((a, b) => {
				return new Date(`01 ${a}`) - new Date(`01 ${b}`)
			})
			.forEach((key) => {
				sortedMonthMembers[key] = monthMembers[key]
			})

		const totalMembersLine = {
			id: 'totalMembers',
			color: theme.palette.secondary.main,
			data: [],
		}

		Object.keys(sortedMonthMembers).forEach((monthYear) => {
			totalMembersLine.data.push({
				x: monthYear,
				y: sortedMonthMembers[monthYear],
			})
		})

		return [[totalMembersLine]]
	}, [members])

	if (isLoading || !members) return <Box>Loading...</Box>

	return (
		<ResponsiveLine
			data={totalMembersLine}
			theme={{
				axis: {
					domain: {
						line: {
							stroke: theme.palette.secondary[200],
						},
					},
					legend: {
						text: {
							fill: theme.palette.secondary[200],
						},
					},
					ticks: {
						line: {
							stroke: theme.palette.secondary[200],
							strokeWidth: 1,
						},
						text: {
							fill: theme.palette.secondary[200],
						},
					},
				},
				legends: {
					text: {
						fill: theme.palette.secondary[300],
					},
				},
				tooltip: {
					container: {
						color: theme.palette.primary.main,
					},
				},
			}}
			margin={{ top: 20, right: 50, bottom: 50, left: 70 }}
			xScale={{ type: 'point' }}
			yScale={{
				type: 'linear',
				min: 'auto',
				max: 'auto',
				stacked: false,
				reverse: false,
			}}
			yFormat=" >-.2f"
			curve="catmullRom"
			enableArea={isDashboard}
			axisTop={null}
			axisRight={null}
			axisBottom={{
				format: (v) => {
					if (isDashboard) return v.slice(0, 3)
					return v
				},
				orient: 'bottom',
				tickSize: 5,
				tickPadding: 5,
				tickRotation: 0,
				legend: isDashboard ? '' : 'Month',
				legendOffset: 36,
				legendPosition: 'middle',
			}}
			axisLeft={{
				orient: 'left',
				tickValues: 5,
				tickSize: 5,
				tickPadding: 5,
				tickRotation: 0,
				legend: isDashboard
					? ''
					: `Total ${'totalMembers' === 'totalMembers'} for Year`,
				legendOffset: -60,
				legendPosition: 'middle',
			}}
			enableGridX={false}
			enableGridY={false}
			pointSize={10}
			pointColor={{ theme: 'background' }}
			pointBorderWidth={2}
			pointBorderColor={{ from: 'serieColor' }}
			pointLabelYOffset={-12}
			useMesh={true}
			legends={
				isDashboard
					? [
							{
								anchor: 'bottom-right',
								direction: 'column',
								justify: false,
								translateX: 30,
								translateY: -200,
								itemsSpacing: 0,
								itemDirection: 'left-to-right',
								itemWidth: 80,
								itemHeight: 20,
								itemOpacity: 0.75,
								symbolSize: 12,
								symbolShape: 'circle',
								symbolBorderColor: 'rgba(0, 0, 0, .5)',
								effects: [
									{
										on: 'hover',
										style: {
											itemBackground: 'rgba(0, 0, 0, .03)',
											itemOpacity: 1,
										},
									},
								],
							},
					  ]
					: undefined
			}
		/>
	)
}

export default MembersChart
