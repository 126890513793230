import { Button } from '@mui/material'
import React from 'react'

const ActionButton = (props) => {
	const { color, children, onClick, ...other } = props
	return (
		<Button
			sx={{
				backgroundColor: color === 'primary' ? '#4dff9d' : '#e65353',
				color: '#fff',
				padding: '0.5rem',
				margin: '0 0.5rem',
				size: '1.5rem',
			}}
			onClick={onClick}>
			{children}
		</Button>
	)
}

export default ActionButton
