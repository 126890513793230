import React from 'react'
import Login from 'components/Login'
import Hero from 'assets/log.svg'
import { useTheme } from '@mui/material'

const Landing = () => {
	const theme = useTheme()
	return (
		<>
			<div className=" grid lg:grid-cols-2 grid-cols-1 w-screen h-screen bg-main-bg dark:bg-secondary-dark-bg">
				<div className="flex justify-center items-center p-5 sm:p-10 md:p-20 lg:p-0">
					<Login />
				</div>
				<div className="h-[600px] w-[600px] z-[9] bottom-0 right-10 absolute hidden lg:block ">
					<img src={Hero} alt="" />
				</div>
			</div>
			<div
				className="rounded-full h-[1000px] w-[1000px] fixed top-[-250px] right-[-300px]"
				style={{ background: theme.palette.secondary[300] }}></div>
		</>
	)
}

export default Landing
