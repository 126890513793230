import { useStateContext } from "contexts/ContextProvider";
import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import { Box, useTheme } from "@mui/material";
function Transactions() {
  const [pageSize, setPageSize] = React.useState(10);

  const { memberData } = useStateContext();
  const [transactions, setTransactions] = useState({});
  const theme = useTheme();

  const columns = [
    { field: "campaignName", headerName: "Group Name", flex: 1 },
    {
      field: "creationDate",
      headerName: "Creation Date",
      flex: 0.5,
      renderCell: (params) => format(new Date(params.value), "dd/MM/yyyy"),
    },
    { field: "type", headerName: "Type", flex: 0.5 },
    { field: "status", headerName: "Status", flex: 0.5 },
    { field: "purpose", headerName: "Category", flex: 0.5 },

    {
      field: "amount",
      headerName: "Amount",
      flex: 1,
      renderCell: (params) => `ZMW${Number(params.value).toFixed(2)}`,
    },
  ];
  //---------------------------------------------------------------------------------------------------------------------------
  //Kalan you can insert the equivalent fundtion here
  const instance = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    headers: { "Content-Type": "application/json", Accept: "application/json" },
  });

  useEffect(() => {
    const getCustomerTransactions = async () => {
      try {
        const { data } = await instance.post("/users/transactions", memberData);
        setTransactions(data);
        console.log(memberData);
        return;
      } catch (error) {
        throw new Error(error);
      }
    };
    getCustomerTransactions();
  }, [memberData]);
  //---------------------------------------------------------------------------------------------------------------------------

  return (
    <Box
      mt="0px"
      height="50vh"
      sx={{
        "& .MuiDataGrid-root": { border: "none" },
        "& .MuiDataGrid-cell": { borderBottom: "none" },
        "& .MuiDataGrid-columnHeaders": {
          backgroundColor: theme.palette.background.alt,
          color: theme.palette.secondary[100],
          borderBottom: "none",
        },
        "& .MuiDataGrid-virtualScroller": {
          backgroundColor: theme.palette.primary.light,
        },
        "& .MuiDataGrid-footercontainer": {
          backgroundColor: theme.palette.background.alt,
          color: theme.palette.secondary[100],
          borderTop: "none",
        },
        "& .MuiDataGrid-toolbatContainer .MuiButton-text": {
          color: `${theme.palette.secondary[200]} !important`,
        },
      }}
    >
      <DataGrid
        // loading={isLoading || !data}
        getRowId={(row) => row.id}
        rows={transactions || []}
        columns={columns}
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        initialState={{
          sorting: {
            sortModel: [{ field: "creationDate", sort: "desc" }],
          },
        }}
        rowsPerPageOptions={[10, 20, 50]}
        pagination
      />
    </Box>
  );
}

export default Transactions;
