import { apiSlice } from 'app/api/apiSlice'

export const usersApiSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getUsers: build.query({
      query: (branch) => ({
        url: `users/bybranch/${branch}`,
        method: "POST",
        body: {
          branch,
        },
        providesTags: ["Users"],
      }),
    }),
    getChartUsers: build.query({
      query: (branch) => ({
        url: `users/chart/${branch}`,
        method: "GET",
        providesTags: ["Users"],
      }),
    }),
    getCustomerCampaign: build.query({
      query: (userId) => ({
        url: "users/campaigns",
        method: "POST",
        body: {
          ...userId,
        },
      }),
    }),
    getCustomerTransactions: build.query({
      query: (userId) => ({
        url: "users/transactions",
        method: "POST",
        body: {
          ...userId,
        },
      }),
    }),
  }),
});

export const {
	useGetUsersQuery,
	useGetCustomerCampaignQuery,
	useGetCustomerTransactionsQuery,
	useGetChartUsersQuery,
} = usersApiSlice
