import React, { useState, useEffect } from 'react'
import Header from 'components/Header'
import { CSVLink } from 'react-csv'
import PDFFile from 'components/PDFFile'
import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer'
import useAuth from 'hooks/useAuth'
import debug from 'debug'
import FlexBetween from 'components/FlexBetween'
import SearchIcon from '@mui/icons-material/Search'
import FormLabel from '@mui/material/FormLabel'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import {
  Box,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  useTheme,
} from '@mui/material'
import ReactDatePicker from 'react-datepicker'
import {
  useConsolidatedReportDataMutation,
  useGroupTransactionReportDataMutation,
  useIndividualTransactionReportDataMutation,
} from 'slices/report/reportApiSlice'
import moment from 'moment/moment'
const log = debug('app:UnprocessedGroupReportData')

const Reports = () => {
  const [reportType, setReportType] = useState('Group')
  const [reportFormat, setReportFormat] = useState('')
  const [csvPrint, setCsvPrint] = useState(false)
  const [pdfPrint, setPdfPrint] = useState(false)
  const [startDate, setStartDate] = useState()
  const [endDate, setEndDate] = useState()
  const [report, setReport] = useState()
  const [reportParameters, setReportParameters] = useState()
  const [phoneNumber, setPhoneNumber] = useState('')
  const [preReport, setPreReport] = useState([])
  const { username, isSuperAdmin } = useAuth()

  const theme = useTheme()

  const [
    groupTransactionReportData,
    { isError: isErrorGroup, error: errorGroup, isLoadingGroup },
  ] = useGroupTransactionReportDataMutation()

  const [individualTransactionReportData] =
    useIndividualTransactionReportDataMutation()

  const [consolidatedReportData] = useConsolidatedReportDataMutation()

  // get unprocessed report data

  let reportData = []

  const getUnprocessedGroupReportData = async () => {
    if (isErrorGroup) {
      console.log('Error', errorGroup)
    }
    const { data } = await groupTransactionReportData({
      ...reportParameters,
    })
    return (reportData = data)
  }

  const getUnprocessedIndividualReportData = async () => {
    const { data } = await individualTransactionReportData({
      ...reportParameters,
    })
    return (reportData = data)
  }

  const getUnprocessedConsolidatedReportData = async () => {
    const { data } = await consolidatedReportData({
      ...reportParameters,
    })
    return (reportData = data)
  }

  //--------------------------------------------------------------------------------
  useEffect(() => {
    if (startDate && endDate) {
      if (reportType === 'Consolidated') {
        setReportParameters({
          startDate: moment(startDate).format('YYYY-MM-DD'),
          endDate: moment(endDate).format('YYYY-MM-DD'),
        })
      } else {
        setReportParameters({
          startDate: moment(startDate).format('YYYY-MM-DD'),
          endDate: moment(endDate).format('YYYY-MM-DD'),
          phoneNumber,
        })
      }
    }
  }, [startDate, endDate, phoneNumber, reportType])

  const structureReportData = async () => {
    switch (reportType) {
      case 'Individual':
        await getUnprocessedIndividualReportData()
        setPreReport(reportData)
        break
      case 'Group':
        await getUnprocessedGroupReportData()
        setPreReport(reportData)

        break
      case 'Consolidated':
        await getUnprocessedConsolidatedReportData(reportParameters)
        setPreReport(reportData)
        break
      default:
        log('Invalid report type selected')
    }
    if (!isLoadingGroup) {
      if (reportData.length > 0) {
        setReport(createReport(phoneNumber, startDate, endDate))
        console.log(
          '🚀 ~ file: index.jsx:127 ~ structureReportData ~ Report',
          report
        )
      } else if (reportData === []) {
        alert('No transactions found for this period')
      } else {
        alert('No transactions found for this period')
      }
    }
  }

  const createReport = (phoneNumber, startDate, endDate) => {
    return {
      groupAdminNumber: phoneNumber,
      openingBalance: reportData[reportData.length - 1].balance,
      closingBalance: reportData[0].balance,
      startDate: moment(startDate).format('YYYY-MM-DD'),
      endDate: moment(endDate).format('YYYY-MM-DD'),
      items: reportData,
    }
  }

  const printReport = () => {
    switch (reportFormat) {
      case 'PDF':
        setPdfPrint(true)
        setCsvPrint(false)
        break
      case 'CSV':
        setCsvPrint(true)
        setPdfPrint(false)
        break
      default:
        log('Invalid report format selected')
    }
  }
  useEffect(() => {
    printReport()
  }, [reportFormat])

  //--------------------------------------------------------------------------------

  return (
    <Box m="1.5rem 2.5rem">
      <Header subtitle="Generate Statements" title="Statements" />
      <Box
        sx={{ backgroundColor: theme.palette.background.alt }}
        mt="1.5rem"
        p="1.5rem 2.5rem"
        height="100%"
      >
        <div className="">
          <FlexBetween component="form">
            <FormControl sx={{ mt: '1rem' }}>
              <FormLabel id="demo-row-radio-buttons-group-label">
                Select Type
              </FormLabel>
              <RadioGroup
                value={reportType}
                onChange={(e) => {
                  setReportType(e.target.value)
                }}
                defaultValue="Group"
                row
              >
                <FormControlLabel
                  value="Group"
                  control={<Radio />}
                  label="Group"
                />
                <FormControlLabel
                  value="Individual"
                  control={<Radio />}
                  label="Individual"
                />
                {isSuperAdmin && (
                  <FormControlLabel
                    value="Consolidated"
                    control={<Radio />}
                    label="Consolidated"
                  />
                )}
              </RadioGroup>
            </FormControl>

            <Box>
              <TextField
                id="search-bar"
                className="text"
                onInput={(e) => {
                  setPhoneNumber(e.target.value)
                }}
                label="Enter Phone Number"
                variant="outlined"
                placeholder="Search..."
                size="small"
              />
              <IconButton onClick={structureReportData} aria-label="search">
                <SearchIcon style={{ fill: 'blue' }} />
              </IconButton>
            </Box>
            <Box
              display="flex"
              sx={{
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <label htmlFor="dateRange" className="font-bold">
                Between
              </label>

              <FlexBetween gap={2}>
                <ReactDatePicker
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  className="text-gray-700 cursor-pointer w-[130px] flex justify-center items-center mt-2 border border-gray-300 rounded-md shadow-sm py-2 px-4 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
                and
                <ReactDatePicker
                  selected={endDate}
                  onChange={(date) => setEndDate(date)}
                  className="text-gray-700 w-[130px] cursor-pointer flex justify-center items-center mt-2 border border-gray-300 rounded-md shadow-sm py-2 px-4 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </FlexBetween>
            </Box>
          </FlexBetween>
        </div>

        <div className="flex  justify-between mb-10">
          <div className="static bottom-3 right-2">
            {report && (
              <FormControl sx={{ mt: '1rem' }}>
                <FormLabel id="demo-row-radio-buttons-group-label">
                  Select Format{' '}
                </FormLabel>
                <RadioGroup
                  value={reportFormat}
                  label="Format"
                  displayEmpty
                  onChange={(e) => {
                    setReportFormat(e.target.value)
                  }}
                  defaultValue="PDF"
                  row
                >
                  <FormControlLabel
                    value="PDF"
                    control={<Radio />}
                    label="PDF"
                  />
                  {isSuperAdmin && (
                    <FormControlLabel
                      value="CSV"
                      control={<Radio />}
                      label="CSV"
                    />
                  )}
                  {isSuperAdmin && (
                    <FormControlLabel
                      value="EXCEL"
                      control={<Radio />}
                      label="EXCEL"
                    />
                  )}
                </RadioGroup>
              </FormControl>
            )}
          </div>
        </div>
        {csvPrint ? (
          <CSVLink data={preReport} filename={`report.csv`}>
            <button className="p-4 px-7 rounded-md bg-slate-400 hover:bg-slate-600 cursor-pointer mx-4 mt-8">
              Download CSV
            </button>
          </CSVLink>
        ) : null}
        {pdfPrint ? (
          <div>
            <PDFViewer width={800} height="400px" showToolbar={false}>
              <PDFFile
                reportType={reportType}
                report={report}
                username={username}
              />
            </PDFViewer>
            {console.log(report)}
            {console.log(preReport)}
            <PDFDownloadLink
              document={
                <PDFFile
                  reportType={reportType}
                  report={report}
                  username={username}
                />
              }
              fileName="Report.pdf"
            >
              {({ loading }) =>
                loading ? (
                  <button>Loading Document</button>
                ) : (
                  <button className="p-4 px-7 rounded-md bg-slate-400 hover:bg-slate-600 cursor-pointer mx-4 mt-8">
                    Download
                  </button>
                )
              }
            </PDFDownloadLink>
          </div>
        ) : (
          ''
        )}
      </Box>
    </Box>
  )
}

export default Reports
