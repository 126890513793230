import {
	Box,
	Button,
	FormControl,
	FormControlLabel,
	Grid,
	InputLabel,
	MenuItem,
	Select,
	Switch,
	TextField,
} from '@mui/material'

import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import {
	useGetAdminsQuery,
	useUpdateAdminMutation,
} from 'slices/admin/adminApiSlice'
import { setOpenEdit } from 'state'

const EditAdmin = ({ id }) => {
	const [updateAdmin, { isLoading, isError, isSuccess, error }] =
		useUpdateAdminMutation()

	const { admin } = useGetAdminsQuery(
		'adminsList',
		{
			selectFromResult: ({ data }) => ({
				admin: data?.entities[id],
			}),
		},
		id,
	)

	const dispatch = useDispatch()

	const [username, setUsername] = useState(admin.username)
	const [email, setEmail] = useState(admin.email)
	const [password, setPassword] = useState('')
	const [roles, setRoles] = useState(admin.roles)
	const [active, setActive] = useState(admin.active)
	const [branch, setBranch] = useState(admin.branch)

	const onUsernameChanged = (e) => setUsername(e.target.value)
	const onPasswordChanged = (e) => setPassword(e.target.value)
	const onEmailChanged = (e) => setEmail(e.target.value)
	const onActiveChanged = () => setActive((prev) => !prev)
	const onBranchChanged = (e) => setBranch(e.target.value)

	const onRolesChanged = (e) => {
		setRoles([e.target.value])
	}

	useEffect(() => {
		if (isSuccess) {
			setUsername('')
			setEmail('')
			setPassword('')
			setRoles([])
			setBranch('')
			dispatch(setOpenEdit(false))
		}
	}, [isSuccess])

	const handleSubmit = async (e) => {
		e.preventDefault()
		if (password) {
			await updateAdmin({
				id: admin.id,
				username,
				email,
				password,
				roles,
				active,
				branch,
			})
		} else {
			await updateAdmin({
				id: admin.id,
				username,
				email,
				roles,
				active,
				branch,
			})
		}
	}

	const errClass = isError ? 'errmsg' : 'offscreen'

	let content
	content = (
		<Box component="form" onSubmit={handleSubmit} noValidate sx={{ m: 3 }}>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<TextField
						variant="outlined"
						label="Username"
						fullWidth
						error={username === ''} // if username is empty, show error
						required
						helperText="Username is required"
						name="username"
						value={username}
						onChange={onUsernameChanged}
					/>
				</Grid>
				<Grid item xs={12}>
					<TextField
						variant="outlined"
						label="Email"
						fullWidth
						error={email === ''} // if email is empty, show error
						required
						helperText="Email is required"
						value={email}
						name="email"
						onChange={onEmailChanged}
					/>
				</Grid>
				<Grid item xs={12}>
					<TextField
						variant="outlined"
						label="Password"
						fullWidth
						error={password === ''} // if password is empty, show error
						required
						type="password"
						autoComplete="new-password"
						value={password}
						name="password"
						onChange={onPasswordChanged}
					/>
				</Grid>
				<Grid item xs={12}>
					<InputLabel id="select-role">Role</InputLabel>
					<FormControl fullWidth>
						<Select
							id="select-role"
							value={roles}
							label="Role"
							error={roles === ''} // if roles is empty, show error
							required
							helperText="Role is required"
							fullWidth
							name="roles"
							onChange={onRolesChanged}>
							<MenuItem value={'SuperAdmin'}>Super Admin</MenuItem>
							<MenuItem value={'BranchManager'}>Branch Manager</MenuItem>
						</Select>
					</FormControl>
				</Grid>
				<Grid item xs={12}>
					<InputLabel id="select-role">Branch</InputLabel>
					<FormControl fullWidth>
						<Select
							id="select-role"
							value={branch}
							label="Branch"
							error={branch === ''} // if roles is empty, show error
							required
							helperText="Branch is required"
							fullWidth
							name="branch"
							onChange={onBranchChanged}>
							<MenuItem value={'Main'}>Main</MenuItem>
							<MenuItem value={'Petauke'}>Petauke</MenuItem>
							<MenuItem value={'Katete'}>Katete</MenuItem>
							<MenuItem value={'Lundazi'}>Lundazi</MenuItem>
							<MenuItem value={'Chipata'}>Chipata</MenuItem>
							<MenuItem value={'Mazabuka'}>Mazabuka</MenuItem>
							<MenuItem value={'Kafue'}>Kafue</MenuItem>
							<MenuItem value={'Choma'}>Choma</MenuItem>
							<MenuItem value={'Livingstone'}>Livingstone</MenuItem>
							<MenuItem value={'Kasama'}>Kasama</MenuItem>
							<MenuItem value={'Sesheke'}>Sesheke</MenuItem>
							<MenuItem value={'Mpika'}>Mpika</MenuItem>
							<MenuItem value={'Kapiri'}>Kapiri</MenuItem>
						</Select>
					</FormControl>
				</Grid>
				<Grid item xs={12}>
					<FormControlLabel
						control={
							<Switch
								checked={active}
								onChange={onActiveChanged}
								name="active"
								color="primary"
							/>
						}
						label="Active"
					/>
				</Grid>
			</Grid>
			<Button
				type="submit"
				color="success"
				variant="contained"
				size="large"
				sx={{ mt: 3, mb: 2, color: 'white' }}>
				{' '}
				{isLoading ? 'Loading...' : 'Edit Admin'}
			</Button>
			<p className={errClass}>{error?.data?.message}</p>
		</Box>
	)

	return content
}

export default EditAdmin
