import React from "react";
import { Page, Text, Image, Document, StyleSheet } from "@react-pdf/renderer";
import logo from "assets/microloan-logo-reports.png";
import ReportInfo from "./generatePDFReport/ReportInfo";
import ReportPDFTable from "./generatePDFReport/ReportPDFTable";
import ReportPDFTitle from "./generatePDFReport/ReportPDFTitle";

const styles = StyleSheet.create({
  page: {
    backgroundColor: "#fff",
    fontFamily: "Helvetica",
    fontSize: 11,
    paddingTop: 30,
    paddingLeft: 50,
    paddingRight: 50,
    lineHeight: 1.5,
    flexDirection: "column",
  },
  logo: {
    width: 200,
    height: 70,
    marginLeft: "auto",
    marginRight: "auto",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
  printedBy: {
    position: "absolute",
    fontSize: 12,
    bottom: 45,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "black",
  },
});

const PDFFile = ({ reportType, report, username }) => {
  return (
    report && (
      <Document>
        <Page size="A4" style={styles.page}>
          <Image style={styles.logo} src={logo} />
          <ReportPDFTitle title={`${reportType} Report`} />
          <ReportInfo report={report} />
          <ReportPDFTable report={report} />
          <Text style={styles.printedBy}>
            `Report Generated By: {username}`
          </Text>
          <Text
            style={styles.pageNumber}
            render={({ pageNumber, totalPages }) =>
              `${pageNumber} / ${totalPages}`
            }
            fixed
          />
        </Page>
      </Document>
    )
  );
};

export default PDFFile;
